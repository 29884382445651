export const isEmail = (email: string) => {
  // eslint-disable-next-line
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && email.length < 256 && regex.test(email);
};

export const isPassword = (value: string) => {
  if (value.length < 8) {
    return false;
  } else if (/[a-z]/.test(value) === false) {
    return false;
  } else if (/[A-Z]/.test(value) === false) {
    return false;
  } else if (/[0-9]/.test(value) === false) {
    return false;
  } else if (/[^0-9a-zA-Z._]/.test(value) === false) {
    return false;
  } else {
    return true;
  }
};

export const isPhone = (value: string) => {
  return /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(value);
};

// else if (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test(value) === false) {
//   return false;
// }
